const initialState = {
	localRecordingState : {
		status  : 'init',
		consent : 'init'
	},
	localRecordingWidth : 640
}

;

const recorder = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_LOCAL_RECORDING_STATE':
		{
			const { status } = action.payload;

			const localRecordingState = state['localRecordingState'];

			localRecordingState.status = status;

			return { ...state,
				localRecordingState : localRecordingState
			};
		}

		case 'SET_LOCAL_RECORDING_CONSENT':
		{
			const { agreed } = action.payload;
			const localRecordingState = state['localRecordingState'];

			localRecordingState.consent = agreed;

			return { ...state,
				localRecordingState : localRecordingState
			};
		}

		case 'SET_LOCAL_RECORDING_WIDTH':
		{
			const { width } = action.payload;
			// const localRecordingState = state['localRecordingWidth'];

			// localRecordingWidth = action.payload;
			// eslint-disable-next-line no-console

			return { ...state,
				localRecordingWidth : width
			};
		}

		default:
			return state;
	}
};

export default recorder;