import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';

import { NODEURL } from '../utilFiles/mdbRoomInfo';
// import Image1 from '../images/webinar-cartoon.png';
// import Image2 from '../images/edumeet-pic1.png';

// const Room = ReactLazyPreload(() => import(/* webpackChunkName: "room" */ './Room'));
const styles = (theme) =>
	({
		root :
		{
			padding    : '1rem',
			fontWeight : 'bold',
			fontSize   : '1.1rem',
			width      : '100%',
			maxWidth   : '40rem'
		},
		title : {
			textAlign : 'center'
		}
	});

const AdLinkPage = (props) =>
{
	const { classes } = props;

	const intl = useIntl();

	useEffect(() =>
	{
		// // redirect
		const curUrl = new URL(window.location.href);
		const adElementId = curUrl.searchParams.get('adElementId');

		// eslint-disable-next-line no-use-before-define
		adRedirect(NODEURL, 'token', adElementId);
	}, []);

	const adRedirect = async (url, token, adElementId) =>
	{
		try
		{
			const response = await fetch(`${url }/ad/ad-url?adElementId=${adElementId}`, {
				method  : 'GET',
				headers : {
					Authorization  : `Bearer ${ token}`,
					'Content-Type' : 'application/json'
				}
			// body : JSON.stringify({
			// 	adElementId : adElementId,
			// 	adPlaceId   : adPlaceId
			// })
			});

			// eslint-disable-next-line no-console
			console.log(response);
			if (response.ok)
			{
				const resData = await response.json();

				// eslint-disable-next-line no-console
				console.log(resData);

				window.location.href = resData.data;

				// return resData;
			// resolve(resData);
			}
			else
			{
			// reject({ message: 'get ads failed' });
				throw new Error('something wrong');
			}
		}
		catch (err)
		{
		// eslint-disable-next-line no-console
			console.log(err);
		// reject(err);
		}

	};

	return (
		<Fragment>
			<div>
				Loading
			</div>
		</Fragment>
	);
};

AdLinkPage.propTypes =
{
	room    : PropTypes.object.isRequired,
	classes : PropTypes.object.isRequired
};

const mapStateToProps = (state) =>
	({
		room : state.room
	});

export default connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room
			);
		}
	}
// )(AboutAppContent);
)(withStyles(styles)(AdLinkPage));