import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';

// import Image1 from '../images/webinar-cartoon.png';
// import Image2 from '../images/edumeet-pic1.png';

// const Room = ReactLazyPreload(() => import(/* webpackChunkName: "room" */ './Room'));
const styles = (theme) =>
	({
		root :
		{
			padding    : '1rem',
			fontWeight : 'bold',
			fontSize   : '1.1rem',
			width      : '100%',
			maxWidth   : '40rem'
		},
		title : {
			textAlign : 'center'
		}
	});

const AboutAppContent = (props) =>
{
	const { classes } = props;

	const intl = useIntl();

	// // change head title description
	document.title = `REMEET-WePL, Watakura ${intl.formatMessage({
		id             : 'aboutWeplApp.text01',
		defaultMessage : 'REMEET-WePL is a simple video presentation app.'
	})}`;

	const descriptionContent = document.querySelector("meta[name='description']");

	descriptionContent.content = `REMEET-WePL - Watakura ${intl.formatMessage({
		id             : 'aboutWeplApp.text01',
		defaultMessage : 'REMEET-WePL is a simple video presentation app.'
	})} ${intl.formatMessage({
		id             : 'aboutWeplApp.text02',
		defaultMessage : 'It includes text chat, screen share, and file share.'
	})} 
${intl.formatMessage({
		id             : 'aboutWeplApp.text03',
		defaultMessage : 'Video presentation using REMEET-WePL allows users to show video talk to remote viewers.'
	})} ${intl.formatMessage({
	id             : 'aboutWeplApp.text04',
	defaultMessage : 'That is suitable for various situations such as video seminar, video presentation, remote work, and much more.'
})} 
${intl.formatMessage({
		id             : 'aboutWeplApp.text07',
		defaultMessage : 'When enabled live broadcast, you can show your video talk in watakura.xyz.'
	})} ${intl.formatMessage({
	id             : 'aboutWeplApp.text08',
	defaultMessage : 'That may enable you to show your talk to more viewers.'
})}`;

	return (
		<Fragment>
			<div className={classes.root}>
				<h3 className={classes.title}>
					{/* {intl.formatMessage({
						id             : 'aboutApp.text01',
						defaultMessage : 'About REMEET'
					})} */}
					REMEET-WePL (Webinar, Presentation, Live)
				</h3>
				<p>
					{intl.formatMessage({
						id             : 'aboutWeplApp.text01',
						defaultMessage : 'REMEET-WePL is a simple video presentation app.'
					})}
					{intl.formatMessage({
						id             : 'aboutWeplApp.text02',
						defaultMessage : 'It includes text chat, screen share, and file share.'
					})}

				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutWeplApp.text03',
						defaultMessage : 'Video presentation using REMEET-WePL allows users to show video talk to remote viewers.'
					})}

					{' '}
					{intl.formatMessage({
						id             : 'aboutWeplApp.text04',
						defaultMessage : 'That is suitable for various situations such as video seminar, video presentation, remote work, and much more.'
					})}

					{' '}
				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutWeplApp.text05',
						defaultMessage : 'Users of REMEET-WePL can communicate with viewers using text chat and file share.'
					})}

				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutWeplApp.text06',
						defaultMessage : 'If you want to communicate with multiple people with video, you can use'
					})}

					{' '}<a href='https://remeet.watakura.xyz/new-room-from-above-link' target='_blank' rel='nofollow noopener noreferrer'>
						REMEET
					</a>.
				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutWeplApp.text07',
						defaultMessage : 'When enabled live broadcast, you can show your video talk in watakura.xyz.'
					})}

					{' '}
					{intl.formatMessage({
						id             : 'aboutWeplApp.text08',
						defaultMessage : 'That may enable you to show your talk to more viewers.'
					})}
				</p>
				<p>
					{intl.formatMessage({
						id             : 'aboutWeplApp.text09',
						defaultMessage : 'Create and Start Your Next Talk at'
					})}

					{' '}<a href='https://bookremeet-wepl.spaceeight.net' target='_blank' rel='nofollow noopener noreferrer'>
						{intl.formatMessage({
							id             : 'aboutWeplApp.text10',
							defaultMessage : 'Room Creation Page'
						})}
					</a>.
				</p>
				<p>
					<a href='https://remeet-wepl.watakura.xyz/your-presentation' target='_blank' rel='nofollow noopener noreferrer'>
						room enter page
					</a>.
				</p>
			</div>
		</Fragment>
	);
};

AboutAppContent.propTypes =
{
	room    : PropTypes.object.isRequired,
	classes : PropTypes.object.isRequired
};

const mapStateToProps = (state) =>
	({
		room : state.room
	});

export default connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room
			);
		}
	}
// )(AboutAppContent);
)(withStyles(styles)(AboutAppContent));