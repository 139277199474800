import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import classnames from 'classnames';
import isElectron from 'is-electron';
import * as settingsActions from '../actions/settingsActions';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CookieConsent from 'react-cookie-consent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import BlockIcon from '@material-ui/icons/Block';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from '@material-ui/icons/Mic';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VideocamIcon from '@material-ui/icons/Videocam';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import randomString from 'random-string';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Checkbox from '@material-ui/core/Checkbox';

// import FirebaseTest from './FirebaseTest';
import { getApi, checkRoom, BOOKPAGE_URL } from '../utilFiles/mdbRoomInfo';

import io from 'socket.io-client';
import { getSignalingUrl } from '../urlFactory';
import { store } from '../store';
import * as meActions from '../actions/meActions';
import * as roomActions from '../actions/roomActions';

import AboutAppContent from './AboutAppContent';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundImage      : `url(${window.config ? window.config.background : null})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
			background : 'yellowgreen'
		},
		dialogPaper :
		{
			width                          : '30vw',
			maxHeight                      : '100%',
			// padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '40vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width : '90vw'
			}
		},
		accountButton :
		{
			padding : 0
		},
		accountButtonAvatar :
		{
			width  : 50,
			height : 50
		},

		green :
		{
			color : '#5F9B2D'
		},
		red :
		{
			color : 'rgba(153, 0, 0, 1)'
		},
		joinButton :
		{
			background : '#2e7031',
			color      : 'white',
			'&:hover'  : {
				backgroundColor : '#2e7031'
			}
		},
		mediaDevicesAnySelectedButton :
		{
			'& .Mui-selected' : {
				color           : 'white',
				backgroundColor : '#5F9B2D',
				'&:hover'       : {
					color           : 'white',
					backgroundColor : '#5F9B2D'
				} }

		},

		mediaDevicesNoneSelectedButton :
		{
			'& .Mui-selected' : {
				color           : 'white',
				backgroundColor : '#f50057',
				'&:hover'       : {
					color           : 'white',
					backgroundColor : '#f50057'
				} }

		},

		languageContainer :
		{
			display        : 'flex',
			justifyContent : 'space-between',
			fontWeight     : 'bolder',
			fontSize       : '1rem',
			cursor         : 'pointer',
			gap            : '1rem'
		},
		errorMessage : {
			fontWeight : 'bolder'
		},
		dialogTopBar : {
			display        : 'flex',
			justifyContent : 'space-between',
			// marginBottom   : '1rem',
			fontWeight     : 'bolder',
			fontSize       : '1rem'
		},
		dialogTopBarBookRoomLink : {
			display    : 'flex',
			alignItems : 'center',
			cursor     : 'pointer',
			'&:hover'  : {
				fontWeight     : 'bold',
				textDecoration : 'underline 3px'
			}
		},
		dialogTopBarBookRoomLinkIcon : {
			marginLeft : '0.25rem',
			width      : '0.8rem'
		},
		liveEnterError : {
			color    : 'red',
			fontSize : '1.25rem'
		},
		joinIcon : {
			marginRight : '0.5rem'
		}

	});

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding    : theme.spacing(2),
		paddingTop : theme.spacing(1)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

const JoinDialog = ({
	roomClient,
	room,
	mediaPerms,
	displayName,
	displayNameInProgress,
	loggedIn,
	myPicture,
	changeDisplayName,
	setMediaPerms,
	classes,
	setAudioMuted,
	setVideoMuted,
	locale,
	localesList,
	me
}) =>
{

	// eslint-disable-next-line no-console
	// console.log('max room', window.location, room);

	// let locationPass = '';

	// //location test
	const curUrl = new URL(window.location.href);
	const loginParam = curUrl.searchParams.get('loginkind');
	// const createRoomPageLink = 'https://bookremeet.watakura.xyz/';

	let plocationPass = '';
	const paramLocationPass = curUrl.searchParams.get('locationPass');

	if (paramLocationPass)
	{
		plocationPass = paramLocationPass;
		// roomActions.setEmbedView(true);
	}

	// if (window.location.pathname.split('/').length >= 3)
	// {
	// 	// // url/liveembed/<userid other infos>/pass/roomid
	// 	plocationPass = window.location.pathname.split('/')[window.location.pathname.split('/').length -2];
	// }

	// if (loginParam)

	let forStateLoginParam;

	if (loginParam && !plocationPass)
	{
		// localStorage.setItem('loginkind', curUrl.searchParams.get('loginkind'));
		forStateLoginParam = curUrl.searchParams.get('loginkind');
	}

	const location = useLocation();

	const history = useHistory();

	const intl = useIntl();

	displayName = displayName.trimLeft();

	const authTypeDefault = (loggedIn) ? 'auth' : 'guest';

	const [ authType, setAuthType ] = useState(authTypeDefault);

	const [ roomId, setRoomId ] = useState(
		decodeURIComponent(location.pathname.slice(1)) ||
		randomString({ length: 8 }).toLowerCase()
	);

	const [ isCreatorLogin, setIsCreatorLogin ] = useState(true);

	const [ passwordInput, setPasswordInput ] = useState(plocationPass);
	const [ showPassword, setShowPassword ] = useState(false);
	const [ joinError, setJoinError ] = useState('');
	// const [ isLngModalOpen, setIsLngModalOpen ] = useState(false);
	const [ anchorEl, setAnchorEl ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ getBackendError, setGetBackendError ] = useState('');

	const [ joinFailMessage, setJoinFailMessage ] = useState();

	const [ locationPass, setLocationPass ] = useState(passwordInput);
	const [ stateLoginParam, setStateLoginParam ] = useState(forStateLoginParam);

	const isMenuOpen = Boolean(anchorEl);

	// useEffect(() =>
	// {
	// 	// eslint-disable-next-line no-console
	// 	console.log('max userRoles', room);
	// }, [ room.state ]);

	useEffect(() =>
	{
		// eslint-disable-next-line no-console
		console.log('useEffect stateLoginParam', stateLoginParam);

		// const loginkind = localStorage.getItem('loginkind');

		let loginkind;

		if (!locationPass)
		{
			// loginkind = localStorage.getItem('loginkind');
			loginkind = stateLoginParam;

			// if (!loginkind ||loginkind !== 'creator')
			// {
			// 	store.dispatch(meActions.setRoomCreator(false));
			// }
			if (loginParam && loginkind && loginkind === 'creator')
			{
				setIsCreatorLogin(true);

				const creatorInfo = JSON.parse(localStorage.getItem('creatorLoginInfo'));

				// eslint-disable-next-line no-use-before-define
				joinCreatorHandler(creatorInfo.password);

			}
			else
			{
				setIsCreatorLogin(false);

				localStorage.setItem('loginkind', 'normal');
				localStorage.removeItem('creatorLoginInfo');

				localStorage.removeItem('presenterLoginInfo');
				// store.dispatch(meActions.setRoomCreator(false));
				// eslint-disable-next-line no-console
				console.log('me max', me);
			}

		}

		if (locationPass)
		{
			// eslint-disable-next-line no-console
			console.log('!locationPass useEffect');
			setIsCreatorLogin(false);
		}

		// else
		// {
		// 	setIsCreatorLogin(false);

		// 	localStorage.setItem('loginkind', 'normal');
		// 	localStorage.removeItem('creatorLoginInfo');

		// 	localStorage.removeItem('presenterLoginInfo');
		// 	// store.dispatch(meActions.setRoomCreator(false));
		// 	// eslint-disable-next-line no-console
		// 	console.log('me max', me);
		// }

	}, []);

	useEffect(() =>
	{
		if (getBackendError || joinError)
		{
			setTimeout(() =>
			{
				setGetBackendError('');
				setJoinError('');
			}, 1000 * 10);
		}

	}, [ getBackendError, joinError ]);

	useEffect(() =>
	{
		window.history.replaceState({}, null, encodeURIComponent(roomId) || '/');

	}, [ roomId ]);

	useEffect(() =>
	{
		(location.pathname === '/') && history.push(encodeURIComponent(roomId));
	});

	const _askForPerms = () =>
	{
		if (mediaPerms.video || mediaPerms.audio)
		{
			navigator.mediaDevices.getUserMedia(mediaPerms);
		}
	};

	const handleSetMediaPerms = (event, newMediaPerms) =>
	{

		if (newMediaPerms !== null)
		{
			setMediaPerms(JSON.parse(newMediaPerms));
		}
	};

	const handleSetAuthType = (event, newAuthType) =>
	{
		if (newAuthType !== null)
		{
			setAuthType(newAuthType);
		}

	};

	useEffect(() =>
	{
		if (locationPass)
		{
			// eslint-disable-next-line no-use-before-define
			joinButtonPressHandler();
		}
	}, []);

	const handleJoin = () =>
	{
		setAudioMuted(false);

		setVideoMuted(false);

		// _askForPerms();

		const encodedRoomId = encodeURIComponent(roomId);

		// // set audio false when join
		// setMediaPerms({ audio: false, video: true });

		// // set video & audio false when join
		setMediaPerms({ audio: false, video: false });

		// roomClient.join({
		// 	roomId    : encodedRoomId,
		// 	joinVideo : mediaPerms.video,
		// 	joinAudio : mediaPerms.audio
		// });

		// const creatorLoginInfo = localStorage.getItem('creatorLoginInfo');

		let creatorLoginInfo;

		if (!locationPass)
		{
			creatorLoginInfo = localStorage.getItem('creatorLoginInfo');
		}

		if (creatorLoginInfo)
		{
			// store.dispatch(meActions.setRoomCreator(true));
			roomClient.join({
				roomId    : encodedRoomId,
				joinVideo : mediaPerms.video,
				joinAudio : mediaPerms.audio,
				rcemail   : creatorLoginInfo.email,
				crpass    : creatorLoginInfo.creatorPass
			});
		}
		else
		{
			// store.dispatch(meActions.setRoomCreator(false));
			roomClient.join({
				roomId    : encodedRoomId,
				joinVideo : mediaPerms.video,
				joinAudio : mediaPerms.audio,
				rcemail   : '',
				crpass    : ''
			});
		}

	};

	const handleFocus = (event) => event.target.select();

	/*
	const handleAuth = () =>
	{
		_askForPerms();

		const encodedRoomId = encodeURIComponent(roomId);

		!loggedIn ?
			roomClient.login(encodedRoomId) :
			roomClient.join({
				roomId    : encodedRoomId,
				joinVideo : mediaPerms.video,
				joinAudio : mediaPerms.audio
			});

	};
	*/

	const handleJoinUsingEnterKey = (event) =>
	{
		if (event.key === 'Enter') document.getElementById('joinButton').click();
	};

	const handleChangeDisplayName = (event) =>
	{
		const { key } = event;

		switch (key)
		{
			case 'Enter':
			case 'Escape':

			{
				displayName = displayName.trim();

				if (displayName === '')
					changeDisplayName(
						`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
				if (room.inLobby)
					roomClient.changeDisplayName(displayName);
				break;
			}
			default:
				break;
		}
	};

	const handleMenuClose = () =>
	{
		setAnchorEl(null);

		// handleMobileMenuClose();
	};

	const passwordInputHandler = (event) =>
	{
		setPasswordInput(event.target.value);

		// setGetBackendError('');
	};

	// const lngModalOpenHndler = () =>
	// {
	// 	if (isLngModalOpen)
	// 	{
	// 		setAnchorEl(null);
	// 	}
	// 	setIsLngModalOpen(!isLngModalOpen);

	// };

	const checkRoomHandler = (idInput, passInput) =>
	{
		if (!locationPass)
		{
			localStorage.removeItem('roomInfo');
		}

		setJoinError('');
		setIsLoading(true);

		checkRoom(idInput, passInput)
			.then((res) =>
			{
				// eslint-disable-next-line no-console
				console.log('checkroom res', res);

				store.dispatch(meActions.setRoomInfo(res.data));

				// // throw error now is more then 10mins before start. 
				// eslint-disable-next-line no-console
				if (res.data.start - res.data.allowBeforeStart > Date.now())
				{
					// eslint-disable-next-line no-console
					console.log(res.data.start- 1000*60*10, Date.now());

					if (locationPass)
					{
						setJoinFailMessage('not-yet-start');

						return null;
					}

					throw new Error('Too early to enter room');
				}

				// store.dispatch(meActions.setRoomInfo(res.data));

				if (locationPass && !res.data.useLive)
				{
					setJoinFailMessage('no-live-room');

					return null;
					// throw new Error('no-live-room');
				}

				handleJoin();
				// setIsLoading(false);
			})
			.catch((err) =>
			{
				// eslint-disable-next-line no-console
				console.log('checkroom err', err);
				setJoinError(
					intl.formatMessage({
						id             : 'room.joinError',
						defaultMessage : 'Entering room failed. Please confirm room name and password. Or, room name and password were already expired.'
					})
				);

				if (locationPass)
				{
					setJoinFailMessage('room-enter-error');
				}

				setIsLoading(false);
			});
	};

	const getSocketHandler = () =>
	{
		return new Promise((resolve, reject) =>
		{
			setIsLoading(true);

			//
			//
			//
			// // for deploy firebase cors
			const socket = io.connect(getSignalingUrl('', ''), {
				withCredentials  : true,
				transportOptions : {
					polling : {
						extraHeaders : {
							'my-custom-header' : 'abcd'
						}
					}
				}
			});

			//
			// //for local dev
			// const socket = io.connect(getSignalingUrl('', ''));
			// //// const socket = io.connect(`wss://${process.env.REACT_APP_SERVER_ADDRESS}:442/?peerId=y&roomId=x`);
			//
			//

			// console.log(socket, 'connect state', socket.connected);

			socket.on('connect', function()
			{
				// socket connected
				// eslint-disable-next-line no-console
				console.log('Socket Connected'
				// socket, 'connect state', socket.connected
				);
				setIsLoading(false);

				// // disconnect test connection
				socket.disconnect();
				// eslint-disable-next-line no-console
				console.log('after disconnect()');
				resolve({ message : 'Socket Connected'
				// , data: socket 
				});
			});

			socket.on('connect_error', function()
			{
				// eslint-disable-next-line no-console
				console.log('Socket Connection failed');
				setGetBackendError(
					intl.formatMessage({
						id             : 'room.getApiError',
						defaultMessage : 'Service is not available now.'
					})
				);
				setIsLoading(false);
				reject({ message: 'Socket connect failed' });
			});

			socket.on('reconnect_failed', function()
			{
				// eslint-disable-next-line no-console
				console.log('Socket Reconnection failed');
			});
		});
	};

	const getBackendHandler = () =>
	{
		return new Promise((resolve, reject) =>
		{
			setIsLoading(true);

			getApi()
				.then((res) =>
				{
				// eslint-disable-next-line no-console
					console.log(res);
					setIsLoading(false);

					return getSocketHandler();
				})
				.then((result) =>
				{
					// eslint-disable-next-line no-console
					console.log(result);

					resolve({ message: 'Get backend api and socket connect success' });
				})
				.catch((err) =>
				{
				// eslint-disable-next-line no-console
					console.log(err);
					setGetBackendError(
						intl.formatMessage({
							id             : 'room.getApiError',
							defaultMessage : 'Service is not available now.'
						})
					);
					setIsLoading(false);

					reject(err);
				});
		});
	};

	const joinButtonPressHandler = () =>
	{
		getBackendHandler()
			.then((res) =>
			{
			// eslint-disable-next-line no-console
				console.log(res);

				checkRoomHandler(roomId, passwordInput);
			})
			.catch((err) =>
			{
			// eslint-disable-next-line no-console
				console.log(err);
			});
	};

	const joinCreatorHandler = (password) =>
	{
		getBackendHandler()
			.then((res) =>
			{
			// eslint-disable-next-line no-console
				console.log(res);

				checkRoomHandler(roomId, password);
			})
			.catch((err) =>
			{
			// eslint-disable-next-line no-console
				console.log(err);
			});
	};

	// // body-part
	let joinDialogBody;

	if (locationPass)
	{
		joinDialogBody = (
			<div style={{ color: 'gray', fontSize: '1.5rem' }}>
				Loading...
				<Box sx={{ display: 'flex' }}>
					<CircularProgress disableShrink/>
				</Box>
			</div>
		);
	}
	if (locationPass && joinFailMessage === 'no-live-room')
	{
		// eslint-disable-next-line no-console
		// console.log('max joinFailmessage', room, me);

		joinDialogBody = (
			<div className={classes.liveEnterError}>
				{intl.formatMessage({
					id             : 'room.text01',
					defaultMessage : 'no live broadcast in this room'
				})}
			</div>
		);
	}
	if (locationPass && joinFailMessage === 'room-enter-error')
	{
		joinDialogBody = (
			<div className={classes.liveEnterError}>
				{intl.formatMessage({
					id             : 'room.text02',
					defaultMessage : 'Entering in live broadcast failed. Please check URL, or live broadcast already finished.'
				})}
			</div>
		);
	}
	if (locationPass && joinFailMessage === 'not-yet-start')
	{
		joinDialogBody = (
			<div className={classes.liveEnterError}>
				{intl.formatMessage({
					id             : 'room.text03',
					defaultMessage : 'Live broadcast does not yet started.'
				})}

				<div>
					{intl.formatMessage({
						id             : 'room.text04',
						defaultMessage : 'Start time'
					})}:
					{' '}
					{new Date(me.roomInfo.start).toLocaleString()}</div>
				{/* <div>end: {new Date(me.roomInfo.end).toLocaleString()}</div> */}
				<div>
					{intl.formatMessage({
						id             : 'room.text05',
						defaultMessage : 'Room will be available 10 minutes before start time.'
					})}
				</div>
			</div>
		);
	}
	if (!locationPass)
	{
		joinDialogBody = (
			<div className={classes.root}>
				<Dialog
					onKeyDown={handleJoinUsingEnterKey}
					open
					classes={{
						paper : classes.dialogPaper
					}}
				>
					{/* <DialogTitle>
						<div className={classes.dialogTopBar}>
							<div />
							<div>
								<a href={createRoomPageLink} target='_blank' rel='noopener noreferrer'>
									create-new-room
								</a>
							</div>
						</div>
					</DialogTitle> */}

					<DialogTitle disableTypography className={classes.dialogTitle}>
						{/* <Grid
							container
							direction='row'
							justify='space-between'
							alignItems='center'
						>
							<Grid item>
								{ window.config.logo !=='' ?
									<img alt='Logo' src={window.config.logo} /> :
									<Typography variant='h5'> {window.config.title} </Typography>
								}
							</Grid>
							<Grid item>
								{ window.config.loginEnabled &&
								<Tooltip
									open
									title={intl.formatMessage({
										id             : loggedIn ? 'label.logout' : 'label.login',
										defaultMessage : loggedIn ? 'Logout' : 'Login'
									})}
									placement='left'
								>
									<IconButton
										className={classes.accountButton}
										onClick={
											loggedIn ?
												() => roomClient.logout(roomId) :
												() => roomClient.login(roomId)
										}
									>
										{ myPicture ?
											<Avatar src={myPicture} className={classes.accountButtonAvatar} />
											:
											<AccountCircle
												className={
													classnames(
														classes.accountButtonAvatar, loggedIn ? classes.green : null
													)
												}
											/>
										}
									</IconButton>
								</Tooltip>
								}
	
							</Grid>
						</Grid> */}

						<div className={classes.languageContainer}>
							{/* <span/> */}

							<div className={classes.languageContainer}>
								<span onClick={(event) =>
								{
								// lngModalOpenHndler();
									setAnchorEl(event.currentTarget);
								}}
								>
									{intl.formatMessage({
										id             : 'aboutApp.text01',
										defaultMessage : 'About this App'
									})}
									{' '}(REMEET-WePL) &#9662;
								</span>

								<Popover
									anchorEl={anchorEl}
									// anchorReference='anchorPosition'
									// anchorPosition={{ top: 0, left: 100 }}
									anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
									transformOrigin={{ vertical: 'top', horizontal: 'right' }}
									// open={isLngModalOpen}
									open={isMenuOpen}
									onClose={handleMenuClose}
									// onExited={handleExited}
									getContentAnchorEl={null}
								>
									<span className={classes.languageContainer}
									// onClick={lngModalOpenHndler}
									// eslint-disable-next-line react/jsx-no-duplicate-props
										onClick={() => { setAnchorEl(null); }}
									>
										<span />
										<CloseIcon
											fontSize='large'
										/>
									</span>

									<Paper>
										<div>
											<AboutAppContent />
										</div>
									</Paper>
								</Popover>
							</div>

							<div className={classes.dialogTopBarBookRoomLink}>
								<a href={BOOKPAGE_URL} target='_blank' rel='noopener noreferrer'>
									{intl.formatMessage({
										id             : 'label.createPageLink',
										defaultMessage : 'Create new room'
									})}
								</a>
								<OpenInNewIcon
									className={classes.dialogTopBarBookRoomLinkIcon}
								/>
							</div>

							{/* <span onClick={(event) =>
							{
								// lngModalOpenHndler();
								setAnchorEl(event.currentTarget);
							}}
							>
								{locale ? locale.toUpperCase() : null}
							</span>

							<Popover
								anchorEl={anchorEl}
								// anchorReference='anchorPosition'
								// anchorPosition={{ top: 0, left: 100 }}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
								transformOrigin={{ vertical: 'top', horizontal: 'right' }}
								// open={isLngModalOpen}
								open={isMenuOpen}
								onClose={handleMenuClose}
								// onExited={handleExited}
								getContentAnchorEl={null}
							>
								<span className={classes.languageContainer}
									// onClick={lngModalOpenHndler}
									// eslint-disable-next-line react/jsx-no-duplicate-props
									onClick={() => { setAnchorEl(null); }}
								>
									<span />
									<CloseIcon
										fontSize='large'
									/>
								</span>

								<Paper>
									{localesList.map((item, index) => (
										<MenuItem
											selected={item.locale.includes(locale)}
											key={index}
											onClick={() =>
											{
												roomClient.setLocale(item.locale[0]);
												handleMenuClose();
											}}
										>
											{item.name} ({item.file.toUpperCase()})
										</MenuItem>)
									)}
								</Paper>
							</Popover> */}

						</div>

					</DialogTitle>

					<DialogContent>
						{/* <hr /> */}

						{/* <FirebaseTest /> */}

						{/* ROOM NAME */}
						<TextField
							autoFocus
							id='roomId'
							label={intl.formatMessage({
								id             : 'label.roomName',
								defaultMessage : 'Room name'
							})}
							value={roomId}
							variant='outlined'
							margin='normal'
							InputProps={{
								startAdornment : (
									<InputAdornment position='start'>
										<MeetingRoomIcon />
									</InputAdornment>
								)
							}}
							onChange={(event) =>
							{
								const { value } = event.target;

								setRoomId(value.toLowerCase());

							}}
							onFocus={handleFocus}
							onBlur={() =>
							{
								if (roomId === '')
									setRoomId(randomString({ length: 8 }).toLowerCase());
							}}
							fullWidth
						/>
						{/* /ROOM NAME */}

						<TextField
							// autoFocus
							id='password'
							label={intl.formatMessage({
								id             : 'label.password',
								defaultMessage : 'Password'
							})}
							type={!showPassword ? 'password' : ''}
							// label='password-input'
							value={passwordInput}
							variant='outlined'
							margin='normal'
							InputProps={{
								startAdornment : (
									<InputAdornment position='start'>
										<VpnKeyIcon />
									</InputAdornment>
								)
							}}
							onChange={(event) =>
							{
								// const { value } = event.target;
								// setRoomId(value.toLowerCase());
								passwordInputHandler(event);
							}}
							onFocus={handleFocus}
							onBlur={() =>
							{
								// if (roomId === '')
								// 	setRoomId(randomString({ length: 8 }).toLowerCase());
							}}
							fullWidth
						/>
						<div>
							<Checkbox
								// defaultChecked
								onChange={() => { setShowPassword(!showPassword); }}
								color='default'
								inputProps={{ 'aria-label': 'checkbox with default color' }}
							/>
							<span>
								{intl.formatMessage({
									id             : 'label.showPassword',
									defaultMessage : 'display password input'
								})}
							</span>
						</div>

						{/* AUTH TOGGLE BUTTONS */}
						{false &&
						<Grid container
							direction='row'
							justify='space-between'
							alignItems='center'
						>
							<Grid item>
								<ToggleButtonGroup
									value={authType}
									onChange={handleSetAuthType}
									aria-label='choose auth'
									exclusive
								>
									<ToggleButton value='guest'>
										<WorkOutlineIcon/>&nbsp;

										<FormattedMessage
											id='room.joinRoomm'
											defaultMessage='Guest'
										/>
									</ToggleButton>

									<ToggleButton value='auth'>
										<VpnKeyIcon/>&nbsp;

										<FormattedMessage
											id='room.joinRoomm'
											defaultMessage='Auth'
										/>
									</ToggleButton>

								</ToggleButtonGroup >

							</Grid>

						</Grid>
						}
						{/* /AUTH TOGGLE BUTTONS */}

						{/* NAME FIELD */}
						<TextField
							id='displayname'
							label={intl.formatMessage({
								id             : 'label.yourName',
								defaultMessage : 'Your name'
							})}
							value={displayName}
							variant='outlined'
							onFocus={handleFocus}

							InputProps={{
								startAdornment : (
									<InputAdornment position='start'>
										<AccountCircle />
									</InputAdornment>
								)
							}}

							margin='normal'
							disabled={displayNameInProgress}
							onChange={(event) =>
							{
								const { value } = event.target;

								changeDisplayName(value);
							}}
							onKeyDown={handleChangeDisplayName}
							onBlur={() =>
							{
								displayName = displayName.trim();

								if (displayName === '')
									changeDisplayName(`Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`);
								if (room.inLobby)
									roomClient.changeDisplayName(displayName);
							}}
							fullWidth
						/>
						{/* NAME FIELD*/}

						{!room.inLobby && room.overRoomLimit &&
						<DialogContentText className={classes.red} variant='h6' gutterBottom>
							<FormattedMessage
								id='room.overRoomLimit'
								defaultMessage={
									'The room is full, retry after some time.'
								}
							/>
						</DialogContentText>
						}
					</DialogContent>

					{ !room.inLobby ?

						<DialogActions>

							<Grid container
								direction='row'
								justify='space-between'
								alignItems='flex-end'
							>

								{/* MEDIA PERMISSIONS TOGGLE BUTTONS */}
								{window.config.loginEnabled &&
								<Grid item>
									<FormControl component='fieldset'>
										<Box mb={1}>
											<FormLabel component='legend'>
												<FormattedMessage
													id='devices.chooseMedia'
													defaultMessage='Choose Media'
												/>
											</FormLabel>
										</Box>
										<ToggleButtonGroup
											value={JSON.stringify(mediaPerms)}
											size='small'
											onChange={handleSetMediaPerms}
											className={
												JSON.stringify(mediaPerms) ===
												'{"audio":false,"video":false}' ?
													classes.mediaDevicesNoneSelectedButton :
													classes.mediaDevicesAnySelectedButton
											}
											aria-label='choose permission'
											exclusive
										>
											<ToggleButton value='{"audio":false,"video":false}'>
												<Tooltip title={intl.formatMessage({
													id             : 'devices.disableBothMicrophoneAndCamera',
													defaultMessage : 'Disable both Microphone And Camera'
												})} placement='bottom'
												>
													<BlockIcon/>
												</Tooltip>
											</ToggleButton>
											<ToggleButton value='{"audio":true,"video":false}'>
												<Tooltip title={intl.formatMessage({
													id             : 'devices.enableOnlyMicrophone',
													defaultMessage : 'Enable only Microphone'
												})} placement='bottom'
												>

													<MicIcon/>
												</Tooltip>
											</ToggleButton>
											<ToggleButton value='{"audio":false,"video":true}'>
												<Tooltip title={intl.formatMessage({
													id             : 'devices.enableOnlyCamera',
													defaultMessage : 'Enable only Camera'
												})} placement='bottom'
												>
													<VideocamIcon/>
												</Tooltip>
											</ToggleButton>
											<ToggleButton value='{"audio":true,"video":true}'>
												<Tooltip title={intl.formatMessage({
													id             : 'devices.enableBothMicrophoneAndCamera',
													defaultMessage : 'Enable both Microphone and Camera'
												})} placement='bottom'
												>
													<span style={{ display: 'flex', flexDirection: 'row' }}>
														<MicIcon/>+<VideocamIcon/>
													</span>
												</Tooltip>
											</ToggleButton>
										</ToggleButtonGroup >
									</FormControl>
								</Grid>
								}
								{/* /MEDIA PERMISSION BUTTONS */}

								{/* JOIN/AUTH BUTTON */}
								<Grid item>
									<Button
										// onClick={handleJoin}
										// onClick={() => { checkRoomHandler(roomId, passwordInput); }}
										onClick={() => { joinButtonPressHandler(); }}
										variant='contained'
										color='primary'
										id='joinButton'
										disabled={!passwordInput.trim() || getBackendError || isLoading}
									>
										{/* <FormattedMessage
											id='label.join'
											defaultMessage='Join'
										/> */}
										<FormattedMessage
											id='other.enterRoom'
											defaultMessage='Enter Room'
										/>
									</Button>

									{/* <br />
									<br /> */}

									<div>
										{isLoading ? (
											<Box sx={{ display: 'flex' }}>
												<CircularProgress disableShrink />
											</Box>
										)
											: null
										}
									</div>

									<div className={classes.errorMessage}>
										<div>{joinError}</div>
										<div>{getBackendError}</div>
									</div>

								</Grid>

								{/*
								{authType === 'auth' && !loggedIn &&
								<Grid item>
									<Button
										onClick={handleAuth}
										variant='contained'
										color='secondary'
										id='joinButton'
									>
										<FormattedMessage
											id='room.login'
											defaultMessage='Next'
										/>
									</Button>
	
								</Grid>
								}
								{authType === 'auth' && loggedIn &&
								<Grid item>
									<Button
										onClick={handleJoin}
										variant='contained'
										className={classes.joinButton}
										id='joinButton'
									>
										<FormattedMessage
											id='room.login'
											defaultMessage='Join'
										/>
									</Button>
	
								</Grid>
								}
								*/}

								{/* /JOIN BUTTON */}

							</Grid>

						</DialogActions>
						:
						<DialogContent>
							<DialogContentText
								className={classes.green}
								gutterBottom
								variant='h6'
								style={{ fontWeight: '600' }}
								align='center'
							>

								{/* <FormattedMessage
									id='room.youAreReady'
									defaultMessage='Ok, you are ready'
								/> */}

							</DialogContentText>
							{ room.signInRequired ?
								<DialogContentText
									gutterBottom
									variant='h5'
									style={{ fontWeight: '600' }}
								>
									<FormattedMessage
										id='room.emptyRequireLogin'
										defaultMessage={
											`The room is empty! You can Log In to start 
											the meeting or wait until the host joins`
										}
									/>
								</DialogContentText>
								:
								// <DialogContentText
								// 	gutterBottom
								// 	variant='h5'
								// 	style={{ fontWeight: '600' }}
								// >
								// 	<FormattedMessage
								// 		id='room.locketWait'
								// 		defaultMessage='The room is locked - hang on until somebody lets you in ...'
								// 	/>
								// 	<span>not enough space in this room</span>
								// </DialogContentText>
								<DialogContentText className={classes.red} variant='h6' gutterBottom>
									<FormattedMessage
										id='room.overRoomLimit'
										defaultMessage={
											'The room is full, retry after some time.'
										}
									/>
								</DialogContentText>
							}
						</DialogContent>
					}

					{ !isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id             : 'room.consentUnderstand',
						defaultMessage : 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
					}
				</Dialog>
			</div>
		);
	}

	return (
		joinDialogBody
	);
};

JoinDialog.propTypes =
{
	roomClient            : PropTypes.any.isRequired,
	room                  : PropTypes.object.isRequired,
	roomId                : PropTypes.string.isRequired,
	displayName           : PropTypes.string.isRequired,
	displayNameInProgress : PropTypes.bool.isRequired,
	loginEnabled          : PropTypes.bool.isRequired,
	loggedIn              : PropTypes.bool.isRequired,
	myPicture             : PropTypes.string,
	changeDisplayName     : PropTypes.func.isRequired,
	setMediaPerms  	      : PropTypes.func.isRequired,
	classes               : PropTypes.object.isRequired,
	mediaPerms            : PropTypes.object.isRequired,
	// setAudioMuted         : PropTypes.bool.isRequired,
	setAudioMuted         : PropTypes.func,
	// setVideoMuted         : PropTypes.bool.isRequired
	setVideoMuted         : PropTypes.func,
	locale                : PropTypes.string.isRequired,
	localesList           : PropTypes.array.isRequired,
	me                    : PropTypes.any.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room                  : state.room,
		mediaPerms            : state.settings.mediaPerms,
		displayName           : state.settings.displayName,
		displayNameInProgress : state.me.displayNameInProgress,
		loginEnabled          : state.me.loginEnabled,
		loggedIn              : state.me.loggedIn,
		myPicture             : state.me.picture,
		locale                : state.intl.locale,
		localesList           : state.intl.list,
		me                    : state.me
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		changeDisplayName : (displayName) =>
		{
			dispatch(settingsActions.setDisplayName(displayName));
		},

		setMediaPerms : (mediaPerms) =>
		{
			dispatch(settingsActions.setMediaPerms(mediaPerms));
		},
		setAudioMuted : (flag) =>
		{
			dispatch(settingsActions.setAudioMuted(flag));
		},
		setVideoMuted : (flag) =>
		{
			dispatch(settingsActions.setVideoMuted(flag));
		}

	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.inLobby === next.room.inLobby &&
				prev.room.signInRequired === next.room.signInRequired &&
				prev.room.overRoomLimit === next.room.overRoomLimit &&
				prev.settings.displayName === next.settings.displayName &&
				prev.settings === next.settings &&
				prev.me.displayNameInProgress === next.me.displayNameInProgress &&
				prev.me.loginEnabled === next.me.loginEnabled &&
				prev.me.loggedIn === next.me.loggedIn &&
				prev.me.picture === next.me.picture
			);
		}
	}
)(withStyles(styles)(JoinDialog)));
