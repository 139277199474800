require('dotenv').config();

//
// // for deploy
export const BOOKPAGE_URL = process.env.REACT_APP_BOOKPAGE_URL;
export const NODEURL = process.env.REACT_APP_NODEJS_URL;
export const ADNETWORKLINK_URL = process.env.REACT_APP_ADNETWORKLINK_URL;
// //
//

//
// // for loacal dev
// export const BOOKPAGE_URL = process.env.REACT_APP_DEV_BOOKPAGE_URL;
// export const NODEURL = process.env.REACT_APP_DEV_NODEJS_URL;
// export const ADNETWORKLINK_URL = process.env.REACT_APP_DEV_ADNETWORKLINK_URL;
//
//

export const getApi = () =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${NODEURL}/example/`)
			.then((res) =>
			{
				if (res.status !== 200 && res.status !== 201)
				{
					throw new Error('get api failed!');
				}

				return res.json();
			})
			.then((resData) =>
			{
				// eslint-disable-next-line no-console
				console.log(resData);

				resolve({ message: 'get api success', data: resData.data });
			})
			.catch((err) =>
			{
				// eslint-disable-next-line no-console
				console.log(err);
				reject({ message: 'get-api-failed', error: err });
			});
	});

};

export const checkRoom = (roomId, password) =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${NODEURL}/room/check-room`, {
		// fetch('/room/check-room', {
			method  : 'POST',
			headers : {
				// Authorization: 'Bearer ' + token,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				roomId   : roomId,
				password : password
			})
		})
			.then((res) =>
			{
				if (res.status !== 200 && res.status !== 201)
				{
					throw new Error('get room info failed!');
				}

				return res.json();
			})
			.then((resData) =>
			{
				// eslint-disable-next-line no-console
				console.log(resData);

				resolve({ message: 'get room info success', data: resData.data });
			})
			.catch((err) =>
			{
				// eslint-disable-next-line no-console
				console.log(err);
				reject({ message: 'get-room-info-failed', error: err });
			});
	});

};

export const creatorLogin = (roomId, email, password, creatorPass) =>
{
	return new Promise((resolve, reject) =>
	{
		fetch(`${NODEURL}/room/get-room`, {
		// fetch('/room/check-room', {
			method  : 'POST',
			headers : {
				// Authorization: 'Bearer ' + token,
				'Content-Type' : 'application/json'
			},
			body : JSON.stringify({
				roomId      : roomId,
				email       : email,
				password    : password,
				creatorPass : creatorPass
			})
		})
			.then((res) =>
			{
				if (res.status !== 200 && res.status !== 201)
				{
					throw new Error('creator login failed!');
				}

				return res.json();
			})
			.then((resData) =>
			{
				// eslint-disable-next-line no-console
				console.log(resData);

				resolve({ message: 'creator logging in success', data: resData.data });
			})
			.catch((err) =>
			{
				// eslint-disable-next-line no-console
				console.log(err);
				reject({ message: 'creator-login-failed', error: err });
			});
	});

};