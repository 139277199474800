const list = [
	{
		name   : 'Afrikaans',
		file   : 'af',
		locale : [ 'af' ]
	},
	{
		name   : 'Arabic',
		file   : 'ar',
		locale : [ 'ar' ]
	},
	{
		name   : 'Azeri (Latin)',
		file   : 'az',
		locale : [ 'az' ]
	},
	{
		name   : 'Belarusian',
		file   : 'be',
		locale : [ 'be' ]
	},
	{
		name   : 'Bulgarian',
		file   : 'bg',
		locale : [ 'bg' ]
	},
	{
		name   : 'Catalan',
		file   : 'ca',
		locale : [ 'ca' ]
	},
	{
		name   : 'Chinese (Traditional)',
		file   : 'zh-TW',
		locale : [ 'zh-TW', 'zn-hk', 'zn-sg' ]
	},
	{
		name   : 'Chinese (Simplified)',
		file   : 'zh',
		locale : [ 'zh', 'zh-CN' ]
	},
	// {
	// 	name   : 'Chinese (Hong Kong)',
	// 	file   : 'zh-HK',
	// 	locale : [ 'zh-HK', 'zn-hk', 'zn-sg', 'zh-TW' ]
	// },
	{
		name   : 'Czech',
		file   : 'cs',
		locale : [ 'cs' ]
	},
	{
		name   : 'Danish',
		file   : 'da',
		locale : [ 'da' ]
	},
	{
		name   : 'German',
		file   : 'de',
		locale : [ 'de' ]
	},
	{
		name   : 'Divehi',
		file   : 'dv',
		locale : [ 'dv' ]
	},
	{
		name   : 'Greek',
		file   : 'el',
		locale : [ 'el' ]
	},
	{
		name   : 'English',
		file   : 'en',
		locale : [ 'en' ]
	},
	{
		name   : 'Esperanto',
		file   : 'eo',
		locale : [ 'eo' ]
	},
	{
		name   : 'Spanish',
		file   : 'es',
		locale : [ 'es' ]
	},
	{
		name   : 'Estonian',
		file   : 'et',
		locale : [ 'et' ]
	},
	{
		name   : 'Basque',
		file   : 'eu',
		locale : [ 'eu' ]
	},
	{
		name   : 'Farsi',
		file   : 'fa',
		locale : [ 'fa' ]
	},
	{
		name   : 'Finnish',
		file   : 'fi',
		locale : [ 'fi' ]
	},
	{
		name   : 'Faroese',
		file   : 'fo',
		locale : [ 'fo' ]
	},
	{
		name   : 'French',
		file   : 'fr',
		locale : [ 'fr' ]
	},
	{
		name   : 'Galician',
		file   : 'gl',
		locale : [ 'gl' ]
	},
	{
		name   : 'Gujarati',
		file   : 'gu',
		locale : [ 'gu' ]
	},
	{
		name   : 'Hebrew',
		file   : 'he',
		locale : [ 'he' ]
	},
	{
		name   : 'Hindi',
		file   : 'hi',
		locale : [ 'hi' ]
	},
	{
		name   : 'Croatian',
		file   : 'hr',
		locale : [ 'hr' ]
	},
	{
		name   : 'Hungarian',
		file   : 'hu',
		locale : [ 'hu' ]
	},
	{
		name   : 'Armenian',
		file   : 'hy',
		locale : [ 'hy' ]
	},
	{
		name   : 'Indonesian',
		file   : 'id',
		locale : [ 'id' ]
	},
	{
		name   : 'Icelandic',
		file   : 'is',
		locale : [ 'is' ]
	},
	{
		name   : 'Italian',
		file   : 'it',
		locale : [ 'it' ]
	},
	{
		name   : 'Japanese',
		file   : 'ja',
		locale : [ 'ja' ]
	},
	{
		name   : 'Georgian',
		file   : 'ka',
		locale : [ 'ka' ]
	},
	{
		name   : 'Kazakh',
		file   : 'kk',
		locale : [ 'kk' ]
	},
	{
		name   : 'Kannada',
		file   : 'kn',
		locale : [ 'kn' ]
	},
	{
		name   : 'Korean',
		file   : 'ko',
		locale : [ 'ko' ]
	},
	{
		name   : 'Konkani',
		file   : 'kok',
		locale : [ 'kok' ]
	},
	{
		name   : 'Kyrgyz',
		file   : 'ky',
		locale : [ 'ky' ]
	},
	{
		name   : 'Lithuanian',
		file   : 'lt',
		locale : [ 'lt' ]
	},
	{
		name   : 'Latvian',
		file   : 'lv',
		locale : [ 'lv' ]
	},
	{
		name   : 'Maori',
		file   : 'mi',
		locale : [ 'mi' ]
	},
	{
		name   : 'FYRO Macedonian',
		file   : 'mk',
		locale : [ 'mk' ]
	},
	{
		name   : 'Mongolian',
		file   : 'mn',
		locale : [ 'mn' ]
	},
	{
		name   : 'Marathi',
		file   : 'mr',
		locale : [ 'mr' ]
	},
	{
		name   : 'Malay',
		file   : 'ms',
		locale : [ 'ms' ]
	},
	{
		name   : 'Maltese',
		file   : 'mt',
		locale : [ 'mt' ]
	},
	{
		name   : 'Norwegian (Bokm?l)',
		file   : 'nb',
		locale : [ 'nb' ]
	},
	{
		name   : 'Dutch',
		file   : 'nl',
		locale : [ 'nl' ]
	},
	{
		name   : 'Northern Sotho',
		file   : 'ns',
		locale : [ 'ns' ]
	},
	{
		name   : 'Punjabi',
		file   : 'pa',
		locale : [ 'pa' ]
	},
	{
		name   : 'Polish',
		file   : 'pl',
		locale : [ 'pl' ]
	},
	{
		name   : 'Pashto',
		file   : 'ps',
		locale : [ 'ps' ]
	},
	{
		name   : 'Portuguese',
		file   : 'pt',
		locale : [ 'pt' ]
	},
	{
		name   : 'Quechua',
		file   : 'qu',
		locale : [ 'qu' ]
	},
	{
		name   : 'Romanian',
		file   : 'ro',
		locale : [ 'ro' ]
	},
	{
		name   : 'Russian',
		file   : 'ru',
		locale : [ 'ru' ]
	},
	{
		name   : 'Sanskrit',
		file   : 'sa',
		locale : [ 'sa' ]
	},
	{
		name   : 'Sami (Northern)',
		file   : 'se',
		locale : [ 'se' ]
	},
	{
		name   : 'Slovak',
		file   : 'sk',
		locale : [ 'sk' ]
	},
	{
		name   : 'Slovenian',
		file   : 'sl',
		locale : [ 'sl' ]
	},
	{
		name   : 'Albanian',
		file   : 'sq',
		locale : [ 'sq' ]
	},
	{
		name   : 'Swedish',
		file   : 'sv',
		locale : [ 'sv' ]
	},
	{
		name   : 'Swahili',
		file   : 'sw',
		locale : [ 'sw' ]
	},
	{
		name   : 'Syriac',
		file   : 'syr',
		locale : [ 'syr' ]
	},
	{
		name   : 'Tamil',
		file   : 'ta',
		locale : [ 'ta' ]
	},
	{
		name   : 'Telugu',
		file   : 'te',
		locale : [ 'te' ]
	},
	{
		name   : 'Thai',
		file   : 'th',
		locale : [ 'th' ]
	},
	{
		name   : 'Tagalog',
		file   : 'tl',
		locale : [ 'tl' ]
	},
	{
		name   : 'Tswana',
		file   : 'tn',
		locale : [ 'tn' ]
	},
	{
		name   : 'Turkish',
		file   : 'tr',
		locale : [ 'tr' ]
	},
	{
		name   : 'Tatar',
		file   : 'tt',
		locale : [ 'tt' ]
	},
	{
		name   : 'Tsonga',
		file   : 'ts',
		locale : [ 'ts' ]
	},
	{
		name   : 'Ukrainian',
		file   : 'uk',
		locale : [ 'uk' ]
	},
	{
		name   : 'Urdu',
		file   : 'ur',
		locale : [ 'ur' ]
	},
	{
		name   : 'Uzbek (Latin)',
		file   : 'uz',
		locale : [ 'uz' ]
	},
	{
		name   : 'Vietnamese',
		file   : 'vi',
		locale : [ 'vi' ]
	},
	{
		name   : 'Xhosa',
		file   : 'xh',
		locale : [ 'xh' ]
	},
	{
		name   : 'Zulu',
		file   : 'zu',
		locale : [ 'zu' ]
	}
];

export const detect = () =>
{
	const localeFull = (navigator.language || navigator.browserLanguage).toLowerCase();

	// const localeCountry = localeFull.split(/[-_]/)[0];

	// const localeRegion = localeFull.split(/[-_]/)[1] || null;

	return localeFull;
};

export const getList = () => list;

export const loadOne = (locale) =>
{
	let res = {};

	// eslint-disable-next-line no-console
	// console.log('locale', locale);

	try
	{
		res = list.filter((item) =>
			item.locale.includes(locale) || item.locale.includes(locale.split(/[-_]/)[0])
		)[0];

		res.messages = require(`./${res.file}`);
	}

	catch
	{

		res = list.filter((item) => item.locale.includes('en'))[0];

		res.messages = require(`./${res.file}`);
	}

	return res;

};
